/* You can add global styles to this file, and also import other style files */

@use "@angular/material" as mat;
@import "styles/theme";
@import "styles/styles";
@import "styles/custom-component-themes";
@import "styles/colors";

// Define your Angular Material theme if not already defined
@include mat.core();

$typography: mat-typography-config();

@include custom-components-theme($dark-theme);

html,
body {
    font-size: 12px;
    width: 100%;
    height: 100%;
    background-color: black;
    overflow-y: hidden !important;
}

@media only screen and (max-height: 580px) {
    body {
        overflow-y: scroll;
    }
}

* {
    font-family: "Arial";
}

.material-icons {
    font-family: "Material Icons";
}

.fa,
.far,
.fas {
    font-family: "Font Awesome 5 Free" !important;
}

@font-face {
    font-family: "EYInterstate-Bold";
    src: url("/assets/fonts/EYInterstate-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "EYInterstate-BoldItalic";
    src: url("/assets/fonts/EYInterstate-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "EYInterstate-Italic";
    src: url("/assets/fonts/EYInterstate-Italic.ttf") format("truetype");
}

@font-face {
    font-family: "EYInterstate-Light";
    src: url("/assets/fonts/EYInterstate-Light.ttf") format("truetype");
}

@font-face {
    font-family: "EYInterstate-LightBold";
    src: url("/assets/fonts/EYInterstate-LightBold.ttf") format("truetype");
}

@font-face {
    font-family: "EYInterstate-LightBoldIt";
    src: url("/assets/fonts/EYInterstate-LightBoldIt.ttf") format("truetype");
}

@font-face {
    font-family: "EYInterstate-LightItalic";
    src: url("/assets/fonts/EYInterstate-LightItalic.ttf") format("truetype");
}

@font-face {
    font-family: "EYInterstate-Regular";
    src: url("/assets/fonts/EYInterstate-Regular.ttf") format("truetype");
}

.inprogres-image {
    width: 35px !important;
    height: 35px !important;
    margin-top: 50px !important;
}

.ey-underline {
    text-decoration: underline;
}

/* Popup Css in ServiceDashboard starts*/
.cdk-overlay-container {
    z-index: 2000 !important;
}

.example-custom-date-class {
    background: red;
    border-radius: 100%;
    pointer-events: none;
}

.modal-header {
    background: cadetblue;
    color: white;
    margin: 0px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem 1rem 0;
}

.modal-content {
    border-radius: 12px;
    max-height: 100%;
}

.modal-height {
    max-height: calc(100% - 100px);
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-dialog {
    display: flex;
    align-items: center;
    height: calc(100% - 42px);
}

.modal-footer {
    button {
        margin-right: 10px;
    }

    button:last-child {
        margin-right: 0;
    }
}

.modal-data {
    padding: 10px 10px 20px;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #747480; //#f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #2e2e38; //#888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */

.toast-center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.toast-top-center {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-left {
    top: 12px;
    left: 12px;
}

.toast-top-right {
    top: 12px;
    right: 12px;
}

.toast-bottom-right {
    right: 12px;
    bottom: 12px;
}

.toast-bottom-left {
    bottom: 12px;
    left: 12px;
}

/* toast styles */

.toast-title {
    font-weight: bold;
}

.toast-message {
    word-wrap: break-word;
}

.toast-message a,
.toast-message label {
    color: #ffffff;
}

.toast-message a:hover {
    color: #cccccc;
    text-decoration: none;
}

.toast-close-button {
    position: relative;
    right: -0.3em;
    top: -0.3em;
    float: right;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 0 1px 0 #ffffff;
    height: 32px;
    width: 32px;
}

.toast-close-button:hover,
.toast-close-button:focus {
    color: #ffe600;
    text-decoration: none;
    cursor: pointer;
    opacity: 1;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/

button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
}

.toast-container {
    pointer-events: none;
    position: fixed;
    z-index: 999999;
}

.toast-container * {
    box-sizing: border-box;
}

.toast-container .ngx-toastr {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 42rem;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px;
    color: #ffffff;
    border-radius: 0;
    box-shadow: none;
    height: 44px;
}

.toast-container .ngx-toastr:hover {
    box-shadow: 0 0 12px #000000;
    opacity: 1;
    cursor: pointer;
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
}

.ngx-toastr {
    background-color: #030303;
    pointer-events: auto;
}

.toast-success {
    background-color: #51a351;
}

.toast-error {
    background-color: #bd362f;
}

.toast-info {
    background-color: #2f96b4;
}

.toast-warning {
    background-color: #f89406;
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #000000;
    opacity: 0.4;
}

/* Responsive Design */

@media all and (max-width: 240px) {
    .toast-container .ngx-toastr.div {
        padding: 8px 8px 8px 50px;
        width: 11em;
    }

    .toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
}

@media all and (min-width: 241px) and (max-width: 480px) {
    .toast-container .ngx-toastr.div {
        padding: 8px 8px 8px 50px;
        width: 18em;
    }

    .toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
}

// colors
$bodyBackground: #111217;
$cardBackground: #181b1f;
$white: #ffffff;
$dark: #000000;
$bluePrimary: #15abde;
$green: #9cc33b;
$transparent: transparent;
$headingColor: #7e8285;
$paraColor: #e0e4e4;
$lightWhite: rgba(255, 255, 255, 0.1);
$lightWhite7: rgba(255, 255, 255, 0.7);
$buttonblue: #41d6c3;
$textGreen: #2f928c;

$borderRadius5: 5px;
$borderRadius10: 10px;
$activeTabColor: #ffe600;
$appLabelColor: #ccccdc;
$appTableRowColor: #111217;
$appTableRowHoverColor: #1e2125;
$appTableHeaderColor: #22252b;
$appPaginatorBackgroundColor: #111217;
$disabledButtonColor: #c0c0c0;
$appModalBackgroundColor: #2e2e38;
$appModalBackgroundColorNew: #181b1f;
$appModalBoxShadow: #010409 0px 8px 24px;
$appModalBorder: 1px solid rgba(204, 204, 220, 0.07);
$selectPanelBorder: 1px solid #22252b;
$selectPanelBoxShadow: #010409 0px 8px 24px;
$selectPaginatorContainerColor: #282836;
$disabledColor: #ffffff4d;
$bulkUploadTemplateBorder: 1px solid #808080;
$deleteIcon: #ff0000;
$tableHeaderColorNew: #23232f;
$tableRowBg1: #1e1e2a;
$tableRowBg2: #2e2e3c;
$highlight: #155cb4;
$closeButton: #1a1a24;
$submitButtonBg: #ffe600;
$fileUploadErrorBg: #b9251c;
$fileUploadBorder: dashed 1px #747480;
$fileUploadBg: #1a1a24;
$submitBtnDisabledBg: #282836;
$eyYellowColor: #ffe600;

@mixin commonLabel($fontSize, $color, $fontWeight, $letterSpacing, $textTransform, $fontFamily) {
    font-size: $fontSize;
    color: $color;
    font-weight: $fontWeight;
    letter-spacing: $letterSpacing;
    text-transform: $textTransform;
    font-family: $fontFamily;
}

@mixin commonSpan($fontSize, $color, $fontWeight, $letterSpacing, $textTransform, $fontFamily) {
    font-size: $fontSize;
    color: $color;
    font-weight: $fontWeight;
    letter-spacing: $letterSpacing;
    text-transform: $textTransform;
    font-family: $fontFamily;
}

@mixin commonPara($fontSize, $color, $fontWeight, $letterSpacing, $textTransform, $fontFamily) {
    font-size: $fontSize;
    color: $color;
    font-weight: $fontWeight;
    letter-spacing: $letterSpacing;
    text-transform: $textTransform;
    font-family: $fontFamily;
}

@mixin textEllipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@mixin commonButton(
    $fontSize,
    $color,
    $fontWeight,
    $letterSpacing,
    $textTransform,
    $fontFamily,
    $padding,
    $margin,
    $background
) {
    font-size: $fontSize;
    color: $color;
    font-weight: $fontWeight;
    letter-spacing: $letterSpacing;
    text-transform: $textTransform;
    font-family: $fontFamily;
    padding: $padding;
    margin: $margin;
    background: $background;
}

@mixin commonCard {
    border-radius: $borderRadius10;
}

@mixin appLabelFontCssStyle($fontSize, $fontWeight, $lineHeight, $color) {
    font-size: $fontSize !important;
    font-weight: $fontWeight !important;
    line-height: $lineHeight !important;
    color: $color !important;
}

@mixin appModalBasicStyle($background, $boxShadow, $border, $borderRadius) {
    background-color: $background;
    box-shadow: $boxShadow;
    border: $border;
    border-radius: $borderRadius;
}

@mixin appLabelFontCssStyle($fontSize, $fontWeight, $lineHeight, $color) {
    font-size: $fontSize !important;
    font-weight: $fontWeight !important;
    line-height: $lineHeight !important;
    color: $color !important;
}

@mixin appModalBasicStyle($background, $boxShadow, $border, $borderRadius) {
    background-color: $background;
    box-shadow: $boxShadow;
    border: $border;
    border-radius: $borderRadius;
}

// common fonts
$font10: 10px;
$font11: 11px;
$font12: 12px;
$font13: 13px;
$font14: 14px;
$font15: 15px;
$font16: 16px;
$font17: 17px;
$font18: 18px;
$font19: 19px;
$font20: 20px;

.bgColor {
    background-color: $cardBackground;
    border: 1px solid #ccccdc12;
    border-radius: 3px;
    box-shadow: none;
}

.bgTransparent {
    background: $transparent;
}

.btn-theme {
    @include commonButton(
        $font12,
        rgba(0, 0, 0, 0.7),
        600,
        unset,
        unset,
        "EYInterstate-Regular",
        0 10px !important,
        0 5px !important,
        $textGreen
    );
    line-height: 30px;
    // color: #fff;
}

.btn-theme:disabled {
    color: $disabledButtonColor;
    cursor: no-drop;
}

.btn-theme:disabled {
    color: $disabledButtonColor;
    cursor: no-drop;
}

.small-button {
    line-height: 23px;
    height: 100%;
    font-size: $font10;
}

.fl-1 {
    flex: 1;
}

.roundness {
    @include commonCard;
}

//telemetry popup css

.telemetry-popup {
    min-width: calc(100% - 250px) !important;
}

.dropdown-menu {
    min-width: calc(100% - 10px) !important;
}

.dropdown {
    ul {
        padding: 0px !important;

        li {
            padding: 5px 10px;

            span {
                padding-left: 5px;
            }
        }
    }
}

//telemetry popup css

.telemetry-popup {
    min-width: calc(100% - 400px) !important;
}

.bread-crumb-container {
    margin: 15px 0 0 0;

    .CV-breadcrumb {
        .CV-breadcrumb-item {
            list-style: none;

            .breadcrumb-root-name {
                font-size: 16px;
                color: $white;
                text-decoration: none;
                font-weight: 400;
            }

            a.active {
                text-decoration: none;
            }
        }
    }
}

.bread-crumb-with-child-container {
    padding: 10px 0;

    .CV-breadcrumb {
        color: $white;
        font-size: 12px !important;
        list-style: none;
        padding-left: 16px;
        display: inline-flex;
        position: relative;
        margin-bottom: 0;

        .CV-breadcrumb-item {
            .breadcrumb-child-name {
                font-size: 12px;
                margin: 0 5px;
                color: $white;
            }

            :hover {
                text-decoration: underline;
                cursor: pointer;
            }

            a.active {
                text-decoration: underline;
                color: $white !important;
            }
        }
    }
}

.secondary-btn-theme {
    border: 1px solid #ccccdc29;
    background-color: #181b1f;
    overflow: hidden;
    position: relative;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-radius: 0;
    color: #ccccdc;
    display: flex;
    font-size: 12px;
    justify-content: center;
    line-height: 1;
    min-height: 30px;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    min-width: 64px;

    &:hover {
        color: #ffe600;
        border-color: #ffe600;
    }
}

// common css for all the modals of the application
.app-modal-template {
    @include appModalBasicStyle($appModalBackgroundColor, $appModalBoxShadow, $appModalBorder, 12px);
    z-index: 1;
    color: #ffffff;
    margin: 0;

    .app-modal-header {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        font-weight: 700;
        font-size: 14px;
    }

    .app-modal-content {
        @include appLabelFontCssStyle(14px, 500, 1.25, $appLabelColor);
        margin: 20px;
    }

    .app-modal-footer {
        margin: 0px 25px 20px 25px;

        .row {
            justify-content: flex-end;
        }
    }
}

.submit-btn-theme {
    display: flex;
    padding: 0 30px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border: 1px solid $appModalBackgroundColorNew;
    background: $submitButtonBg;
    height: 44px;
    color: $appModalBackgroundColorNew !important;
    margin-right: 10px;
    font-size: 13px;
    font-weight: 700;
    border-radius: 0;
    width: 100px;
}

.close-btn-theme {
    display: flex !important;
    padding: 0 30px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border: 1px solid $white;
    background: $closeButton;
    height: 44px;
    color: $white;
    font-size: 13px;
    font-weight: 700;
    border-radius: 0;
    width: 100px;
}

.disable-submit-btn {
    border: 1px solid $grey-480;
    border-color: $grey-480;
    border-radius: 0;
    cursor: no-drop !important;
    padding: 11px 36px;
    @include mxdLabel($grey-400, 14px, 700, 1.5);
    width: 100px;
}

::ng-deep {
    .toast-container {
        height: 44px !important;
        top: 8%;
        .toast-success {
            background-color: #2e2e3c;
            background-image: url("/assets/images/asset-management/success.svg") !important;
        }

        .toast-warning {
            background-color: #2e2e3c;
            background-image: url("/assets/images/asset-management/warning.svg") !important;
        }

        .toast-error {
            background-color: #2e2e3c;
            background-image: url("/assets/images/asset-management/error.svg") !important;
        }
    }
}
