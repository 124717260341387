.ey-ml-68 {
    margin-left: -68px;
}

.ey-ml-46 {
    margin-left: -46px;
}

.ey-mt-40 {
    margin-top: -40px;
}

.ey-digital-twin {
    top: -48px;
    position: absolute;
    width: calc(100% + 46px);
    height: calc(100% + 48px);
}

.ey-fs-20 {
    font-size: 20px;
}

.ey-sb-ac {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ey-pr {
    position: relative;
}

.ey-vc {
    display: flex;
    align-items: center;
}

.ey-h-100 {
    height: 100%;
}

.ey-p-20 {
    padding: 20px;
}

.mat-list-base {
    padding-top: 0 !important;
}
