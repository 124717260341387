// Color Variables

$white: #fff !default;
$black: #1a1a24 !default;
$grey-50: #fafafc !default;
$grey-100: #f6f6fa !default;
$grey-200: #eaeaf2 !default;
$grey-225: #e7e7ea !default;
$grey-250: #e1e1e6 !default;
$grey-300: #c4c4cd !default;
$grey-350: #7f7f91 !default;
$grey-400: #747480 !default;
$grey-450: #3a3a4a !default;
$grey-480: #585860 !default;
$grey-500: #2e2e38 !default;
$grey-550: #2e2e3c !default;
$grey-580: #282836 !default;
$grey-600: #23232f !default;
$grey-700: #1e1e2a !default;
$yellow-400: #ffe600 !default;

$green-100: #8ce8ad !default;
$green-200: #57e188 !default;
$green-300: #34c768 !default;
$green-400: #2db757 !default;
$green-500: #189d3e !default;
$green-600: #168736 !default;
$green-700: #13652a !default;

$teal-100: #93f0e6 !default;
$teal-200: #60e6e1 !default;
$teal-300: #42c9c2 !default;
$teal-400: #27acaa !default;
$teal-500: #109090 !default;
$teal-600: #0d7575 !default;
$teal-700: #004f4f !default;

$blue-100: #87d3f2 !default;
$blue-200: #4ebeeb !default;
$blue-300: #35a4e8 !default;
$blue-400: #188ce5 !default;
$blue-500: #1777cf !default;
$blue-600: #155cb4 !default;
$blue-700: #082c65 !default;

$purple-100: #9c82d4 !default;
$purple-200: #724bc3 !default;
$purple-300: #542ea5 !default;
$purple-400: #3d108a !default;
$purple-500: #240d75 !default;
$purple-600: #0a095a !default;
$purple-700: #15173e !default;

$maroon-100: #c981b2 !default;
$maroon-200: #b14891 !default;
$maroon-300: #922b73 !default;
$maroon-400: #750e5c !default;
$maroon-500: #5a0a42 !default;
$maroon-600: #42152d !default;
$maroon-700: #351c21 !default;

$red-100: #ff9a91 !default;
$red-200: #ff736a !default;
$red-300: #f95d54 !default;
$red-400: #ff4136 !default;
$red-500: #e0362c !default;
$red-600: #b9251c !default;
$red-700: #7a130d !default;

$orange-100: #ffb46a !default;
$orange-200: #ff9831 !default;
$orange-300: #ff810a !default;
$orange-400: #ff6d00 !default;
$orange-500: #f76900 !default;
$orange-600: #eb4f00 !default;
$orange-700: #bc2f00 !default;
$orange-warning: #ed6500 !default;

@mixin mxdLabel($color, $fontSize, $fontWeight, $lineHeight: 1) {
  color: $color !important;
  font-size: $fontSize !important;
  font-weight: $fontWeight !important;
  line-height: $lineHeight !important;
  font-family: "EYInterstate" !important;
}
