// import custom components themes

// you only have to add additional components here (instead of in every theme class)
@use "sass:map";
@use "@angular/material" as mat;

@mixin custom-components-theme($theme) {
  @include mat.all-component-themes($theme);
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  $green-color: #2f928c;
  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, "primary");
  $accent-palette: map.get($color-config, "accent");
  $warn-palette: map.get($color-config, "warn");
  $is-dark-theme: map.get($color-config, "is-dark");

  .md-custom-tree {
    background-color: mat.m2-get-color-from-palette($primary-palette, background);
  }

  .mat-drawer {
    background-color: mat.m2-get-color-from-palette($primary-palette);
  }

  .remove-icon,
  .iot-tree-node-connecting-line,
  .iot-tree-node-connecting-line-vertical,
  .iot-tree-node-main,
  .add-icon {
    border-color: mat.m2-get-color-from-palette($primary-palette, foreground);
  }

  .fa,
  .fas {
    color: mat.m2-get-color-from-palette($primary-palette, foreground);
  }

  .asset-creation-form-main .mat-form-field-label,
  .asset-template-main .mat-form-field.mat-focused .mat-form-field-label {
    color: mat.m2-get-color-from-palette($primary-palette, 100) !important;
  }

  input[type="radio"]:after {
    background-color: mat.m2-get-color-from-palette($primary-palette, 300);
    border-color: mat.m2-get-color-from-palette($primary-palette, 100);
  }

  input[type="radio"]:checked:after {
    background-color: $green-color;
    border-color: mat.m2-get-color-from-palette($primary-palette, 100);
  }

  .errorMessage {
    color: mat.m2-get-color-from-palette($warn-palette);
    font-size: 12px;
  }

  .ey-table-main {
    background-color: mat.m2-get-color-from-palette($primary-palette, 800);

    .mat-paginator {
      background-color: mat.m2-get-color-from-palette($primary-palette, 800);
    }

    .mat-row:nth-child(odd) {
      background-color: mat.m2-get-color-from-palette($primary-palette, 800);
    }

    td {
      color: mat.m2-get-color-from-palette($primary-palette, 400);
    }

    th {
      color: mat.m2-get-color-from-palette($primary-palette, 500);
    }

    .mat-row:nth-child(even),
    th {
      background-color: mat.m2-get-color-from-palette($primary-palette, 900);
    }
  }
}
